/* eslint-disable react/prop-types */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { Button, FormInputField, FormTextField } from "@whitespace/components";
import clsx from "clsx";
import { Formik, Form, FieldArray, Field } from "formik";
import { useStaticQuery, graphql } from "gatsby";
import DevNotice from "gatsby-theme-falkenberg/src/components/DevNotice";
import {
  FormRadioGroupField,
  FormReactSelectField,
  FormSelectField,
  FormCheckboxField,
  FormUploadField,
} from "gatsby-theme-falkenberg/src/components/FormFields";
import {
  formFields,
  FormFieldsSchema,
} from "gatsby-theme-falkenberg/src/hooks/useEventForm";
import { toFormData } from "gatsby-theme-falkenberg/src/utils";
import fetch from "node-fetch";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import * as styles from "./EventSubmitModule.module.css";

class ValidationError extends Error {}

export default function EventSubmitModule({ module, title, ...restProps }) {
  const { t, i18n } = useTranslation();
  const collator = useMemo(
    () => new Intl.Collator(i18n.language),
    [i18n.language],
  );
  const formId = module.databaseId;
  let imageInputDescription = module.modEventSubmit?.imageInput?.description;
  let hideAccessibility = module.modEventSubmit?.accessibility?.hidden;
  let gdprConsentText = module.modEventSubmit?.gdprConsentText;
  // const eventCategories = module.eventCategories || [];
  let eventCategories =
    useStaticQuery(graphql`
      query EventSubmitCategories {
        wp {
          eventCategories(first: 10000) {
            nodes {
              name
              # slug
              databaseId
              language {
                slug
              }
            }
          }
        }
      }
    `).wp?.eventCategories?.nodes || [];
  eventCategories = eventCategories.filter(
    (category) => category.language.slug === i18n.language,
  );
  // const eventLocations = module.eventLocations || [];
  // const eventOrganizers = module.eventOrganizers || [];
  // const userGroups = module.eventUserGroups || [];

  const [submissionError, setSubmissionError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  // if (!userGroups?.length) {
  //   return (
  //     <DevNotice>
  //       No user groups provided for the event form.{" "}
  //       <code>{`userGroups = ${JSON.stringify(userGroups)}`}</code>
  //     </DevNotice>
  //   );
  // }

  const handleSubmitEvent = async (
    {
      image,
      useRcrRules,
      // organizer,
      location,
      // useCustomLocation,
      occasions,
      // useCustomOrganizer,
      rcr_rules,
      gdprConsent,
      ...values
    },
    { resetForm },
  ) => {
    // console.log({
    //   useRcrRules,
    //   organizer,
    //   location,
    //   occasions,
    //   rcr_rules,
    //   ...values,
    // });
    setSubmissionError(null);
    try {
      if (!gdprConsent) {
        throw new Error(
          "Du behöver ge ditt samtycke till lagring av personuppgifter och rättigheter till bilder för att skicka in evenemanget.",
        );
      }
      const data = {
        ...values,
        "occurance-type": values["occurance-type"]
          ? values["occurance-type"]
          : "",
        // organizers: [
        //   useCustomOrganizer
        //     ? { ...organizer, id: undefined, main_organizer: true }
        //     : organizer,
        // ],
        locations: [
          // useCustomLocation ?
          { ...location, id: undefined },
          // : location,
        ],
        // approve: 0, // For when image support is added
        occasions: !useRcrRules
          ? occasions.map(
              ({
                start_date,
                start_time,
                end_date,
                end_time,
                ...occasion
              }) => ({
                ...occasion,
                start_date: `${start_date} ${start_time}`,
                end_date: `${end_date} ${end_time}`,
                status: "scheduled",
                content_mode: "master",
              }),
            )
          : [],
        rcr_rules: useRcrRules ? rcr_rules : [],
      };

      // data.user_groups = userGroups;

      const postEndpoint = `/api/municipio-event-form/forms/${formId}/submissions`;

      // console.log({ postEndpoint, data });

      let body = toFormData({ data: JSON.stringify(data), image });

      const response = await fetch(postEndpoint, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
        },
        body,
      });
      const { success } = await response.json();
      if (!success) {
        throw new Error();
      }
      resetForm();
      setSubmitted(true);
    } catch (error) {
      // console.log(error);
      setSubmissionError(error);
    }
  };

  if (!gdprConsentText) {
    return <DevNotice>Missing GDPR consent text</DevNotice>;
  }

  return (
    <ModuleWrapper title={({ H }) => <H>{title}</H>} {...restProps}>
      <div {...restProps}>
        {submitted ? (
          <div
            css={css`
              background: var(--color-success);
              color: var(--color-success-text);
              padding: 1rem;
            `}
            ref={(el) => {
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Tack för ditt bidrag! Ditt evenemang kommer att granskas av en
            administratör och publiceras så snart som möjligt.
          </div>
        ) : (
          <Formik
            initialValues={{
              title: "",
              content: "",
              image: null,
              useRcrRules: "",
              occasions: [
                {
                  start_date: "",
                  start_time: "",
                  end_date: "",
                  end_time: "",
                },
              ],
              rcr_rules: [
                {
                  rcr_week_day: "",
                  rcr_weekly_interval: "1",
                  rcr_start_time: "",
                  rcr_end_time: "",
                  rcr_start_date: "",
                  rcr_end_date: "",
                },
              ],
              event_link: "",
              booking_link: "",
              price_adult: "",
              price_student: "",
              price_children: "",
              children_age: "",
              price_senior: "",
              senior_age: "",
              // age_group_from: "",
              // age_group_to: "",
              // useCustomOrganizer: "",
              // organizer: {
              //   id: "",
              //   name: "",
              //   phone: "",
              //   email: "",
              // },
              // useCustomLocation: "",
              location: {
                id: "",
                name: "",
                street_address: "",
                postal_code: "",
                city: "",
              },
              accessibility: [],
              event_categories: [],
              // tags: [],
              // image: {},
              submitter_email: "",
              submitter_email_repeat: "",
              submitter_phone: "",
            }}
            validationSchema={FormFieldsSchema}
            onSubmit={handleSubmitEvent}
          >
            {({
              values,
              //  errors,
              isSubmitting,
            }) => {
              return (
                <Form className={styles.form}>
                  {/* <pre>
                    <code>{JSON.stringify({ values, errors }, null, 2)}</code>
                  </pre> */}
                  {/* Title */}
                  {formFields.title && !formFields.title.hidden && (
                    <FormInputField
                      type="text"
                      name={formFields.title.name}
                      id={formFields.title.name}
                      label={formFields.title.label}
                      description={
                        !formFields.title.hidden_description &&
                        formFields.title.description
                      }
                      placeholder={formFields.title.placeholder}
                      required={formFields.title.required}
                      className={clsx(
                        styles.field,
                        formFields.title.required && styles.required,
                      )}
                    />
                  )}
                  {/*  Content */}
                  {formFields.content && !formFields.content.hidden && (
                    <FormTextField
                      name={formFields.content.name}
                      id={formFields.content.name + " textarea"}
                      label={formFields.content.label}
                      description={
                        !formFields.content.hidden_description &&
                        formFields.content.description
                      }
                      rows="10"
                      col="4"
                      required={formFields.content.required}
                      className={clsx(
                        styles.field,
                        formFields.content.required && styles.required,
                      )}
                    />
                  )}
                  {/* Image */}
                  <FormUploadField
                    name={"image"}
                    label={"Bild"}
                    description={imageInputDescription}
                    required={true}
                    className={clsx(styles.field, styles.required)}
                    accept={"image/jpeg, image/png"}
                  />
                  {/* { Occasions} */}
                  {!formFields.useRcrRules.hidden && (
                    <Fragment>
                      <FormRadioGroupField
                        name={formFields.useRcrRules.name}
                        label={formFields.useRcrRules.label}
                        description={
                          !formFields.useRcrRules.hidden_description &&
                          formFields.useRcrRules.description
                        }
                        required={formFields.useRcrRules.required}
                        className={clsx(
                          styles.field,
                          styles.radio,
                          formFields.useRcrRules.required && styles.required,
                        )}
                        options={formFields.useRcrRules.options}
                      />
                      {!values.useRcrRules ? (
                        <FieldArray name="occasions" key="occasions">
                          {({ push, remove }) => (
                            <div className={styles.singleEvent}>
                              {/* <p className={styles.fieldLabel}>
                                {"Enstaka tillfällen"}
                              </p> */}
                              <div className={styles.fieldWrapper}>
                                {values["occasions"].map((item, index) => (
                                  <div
                                    className={styles.fieldGroup}
                                    key={`occasions.${index}`}
                                  >
                                    <div>
                                      <FormInputField
                                        type="date"
                                        label="Startdatum"
                                        name={`occasions.${index}.start_date`}
                                        className={clsx(styles.field)}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="time"
                                        label="Starttid"
                                        name={`occasions.${index}.start_time`}
                                        className={clsx(styles.field)}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="date"
                                        label="Slutdatum"
                                        name={`occasions.${index}.end_date`}
                                        className={clsx(styles.field)}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="time"
                                        label="Sluttid"
                                        name={`occasions.${index}.end_time`}
                                        className={clsx(styles.field)}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                    </div>
                                    {index !== 0 && (
                                      <Button onClick={() => remove(index)}>
                                        {t("Radera tillfället")}
                                      </Button>
                                    )}
                                  </div>
                                ))}
                              </div>

                              <Button
                                css={css`
                                  margin-top: 1rem;
                                `}
                                onClick={() => {
                                  push({
                                    start_date: "",
                                    start_time: "",
                                    end_date: "",
                                    end_time: "",
                                  });
                                }}
                              >
                                {t("Lägg till ett tillfälle")}
                              </Button>
                            </div>
                          )}
                        </FieldArray>
                      ) : (
                        <FieldArray name="rcr_rules" key="rcr_rules">
                          {({ push, remove }) => (
                            <div className={styles.singleEvent}>
                              {/* <p className={styles.fieldLabel}>
                                {"Schema för återkommande evenemang"}
                              </p> */}
                              <div className={styles.fieldWrapper}>
                                {values["rcr_rules"].map((item, index) => (
                                  <div
                                    className={styles.fieldGroup}
                                    key={`rcr_rules.${index}`}
                                  >
                                    <div>
                                      <FormSelectField
                                        label="Veckodag"
                                        description="Evenemanget inträffar på vald veckodag."
                                        name={`rcr_rules.${index}.rcr_week_day`}
                                        className={clsx(styles.field)}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                        options={[
                                          { value: "Monday", label: "Måndag" },
                                          { value: "Tuesday", label: "Tisdag" },
                                          {
                                            value: "Wednesday",
                                            label: "Onsdag",
                                          },
                                          {
                                            value: "Thursday",
                                            label: "Torsdag",
                                          },
                                          { value: "Friday", label: "Fredag" },
                                          {
                                            value: "Saturday",
                                            label: "Lördag",
                                          },
                                          { value: "Sunday", label: "Söndag" },
                                        ]}
                                      />
                                      <FormInputField
                                        type="number"
                                        label="Veckointervall"
                                        description="Ange med vilken veckointervall händelsen inträffar. 1 motsvarar varje vecka."
                                        min="1"
                                        name={`rcr_rules.${index}.rcr_weekly_interval`}
                                        className={clsx(styles.field)}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="time"
                                        label="Starttid"
                                        name={`rcr_rules.${index}.rcr_start_time`}
                                        className={clsx(styles.field)}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="time"
                                        label="Sluttid"
                                        name={`rcr_rules.${index}.rcr_end_time`}
                                        className={clsx(styles.field)}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="date"
                                        label="Från och med datum"
                                        name={`rcr_rules.${index}.rcr_start_date`}
                                        className={clsx(styles.field)}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="date"
                                        label="Till och med datum"
                                        name={`rcr_rules.${index}.rcr_end_date`}
                                        className={clsx(styles.field)}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                    </div>
                                    {index !== 0 && (
                                      <Button onClick={() => remove(index)}>
                                        {t("Radera schemat")}
                                      </Button>
                                    )}
                                  </div>
                                ))}
                              </div>

                              <Button
                                css={css`
                                  margin-top: 1rem;
                                `}
                                onClick={() => {
                                  push({
                                    rcr_week_day: "",
                                    rcr_weekly_interval: "1",
                                    rcr_start_time: "",
                                    rcr_end_time: "",
                                    rcr_start_date: "",
                                    rcr_end_date: "",
                                  });
                                }}
                              >
                                {t("Lägg till ett schema")}
                              </Button>
                            </div>
                          )}
                        </FieldArray>
                      )}
                    </Fragment>
                  )}
                  {/*  Event link */}
                  {formFields.event_link && !formFields.event_link.hidden && (
                    <FormInputField
                      type="text"
                      inputMode="url"
                      name={formFields.event_link.name}
                      id={formFields.event_link.name}
                      label={formFields.event_link.label}
                      description={
                        !formFields.event_link.hidden_description &&
                        formFields.event_link.description
                      }
                      placeholder={formFields.event_link.placeholder}
                      required={formFields.event_link.required}
                      className={clsx(
                        styles.field,
                        formFields.event_link.required && styles.required,
                      )}
                      inputProps={formFields.event_link.inputProps}
                    />
                  )}
                  {/*  Booking link */}
                  {formFields.booking_link && !formFields.booking_link.hidden && (
                      <FormInputField
                        type="text"
                        inputMode="url"
                        name={formFields.booking_link.name}
                        id={formFields.booking_link.name}
                        label={formFields.booking_link.label}
                        description={
                          !formFields.booking_link.hidden_description &&
                          formFields.booking_link.description
                        }
                        placeholder={formFields.booking_link.placeholder}
                        required={formFields.booking_link.required}
                        className={clsx(
                          styles.field,
                          formFields.booking_link.required && styles.required,
                        )}
                        inputProps={formFields.booking_link.inputProps}
                      />
                    )}
                  <fieldset className={clsx(styles.fieldGroupFieldset)}>
                    <legend>
                      <div
                        css={css`
                          font-size: var(--form-label-font-size);
                          font-weight: var(--form-label-weight);
                        `}
                      >
                        Priser
                      </div>
                      <p>
                        Obs. Fyller du inte i pris så kommer ditt evenemang listas som gratis på hemsidan
                      </p>
                    </legend>
                    <div className={styles.fieldGroup}>
                      <div>
                        {/*  Price adult */}
                        {formFields.price_adult &&
                          !formFields.price_adult.hidden && (
                            <FormInputField
                              type="number"
                              name={formFields.price_adult.name}
                              id={formFields.price_adult.name}
                              label={formFields.price_adult.label}
                              description={
                                !formFields.price_adult.hidden_description &&
                                formFields.price_adult.description
                              }
                              placeholder={formFields.price_adult.placeholder}
                              required={formFields.price_adult.required}
                              className={clsx(
                                styles.field,
                                formFields.price_adult.required &&
                                  styles.required,
                              )}
                            />
                          )}
                        {/*  Price student: */}
                        {formFields.price_student &&
                          !formFields.price_student.hidden && (
                            <FormInputField
                              type="number"
                              name={formFields.price_student.name}
                              id={formFields.price_student.name}
                              label={formFields.price_student.label}
                              description={
                                !formFields.price_student.hidden_description &&
                                formFields.price_student.description
                              }
                              placeholder={formFields.price_student.placeholder}
                              required={formFields.price_student.required}
                              className={clsx(
                                styles.field,
                                formFields.price_student.required &&
                                  styles.required,
                              )}
                            />
                          )}
                        {/* Price children */}
                        {formFields.price_children &&
                          !formFields.price_children.hidden && (
                            <FormInputField
                              type="number"
                              name={formFields.price_children.name}
                              id={formFields.price_children.name}
                              label={formFields.price_children.label}
                              description={
                                !formFields.price_children.hidden_description &&
                                formFields.price_children.description
                              }
                              placeholder={
                                formFields.price_children.placeholder
                              }
                              required={formFields.price_children.required}
                              className={clsx(
                                styles.field,
                                formFields.price_children.required &&
                                  styles.required,
                              )}
                            />
                          )}
                        {/* Children age */}
                        {formFields.children_age &&
                          !formFields.children_age.hidden && (
                            <FormInputField
                              type="number"
                              name={formFields.children_age.name}
                              id={formFields.children_age.name}
                              label={formFields.children_age.label}
                              description={
                                !formFields.children_age.hidden_description &&
                                formFields.children_age.description
                              }
                              placeholder={formFields.children_age.placeholder}
                              required={formFields.children_age.required}
                              className={clsx(
                                styles.field,
                                formFields.children_age.required &&
                                  styles.required,
                              )}
                            />
                          )}
                        {/* Price senior */}
                        {formFields.price_senior &&
                          !formFields.price_senior.hidden && (
                            <FormInputField
                              type="number"
                              name={formFields.price_senior.name}
                              id={formFields.price_senior.name}
                              label={formFields.price_senior.label}
                              description={
                                !formFields.price_senior.hidden_description &&
                                formFields.price_senior.description
                              }
                              placeholder={formFields.price_senior.placeholder}
                              required={formFields.price_senior.required}
                              className={clsx(
                                styles.field,
                                formFields.price_senior.required &&
                                  styles.required,
                              )}
                            />
                          )}
                        {/* Senior age */}
                        {formFields.senior_age &&
                          !formFields.senior_age.hidden && (
                            <FormInputField
                              type="number"
                              name={formFields.senior_age.name}
                              id={formFields.senior_age.name}
                              label={formFields.senior_age.label}
                              description={
                                !formFields.senior_age.hidden_description &&
                                formFields.senior_age.description
                              }
                              placeholder={formFields.senior_age.placeholder}
                              required={formFields.senior_age.required}
                              className={clsx(
                                styles.field,
                                formFields.senior_age.required &&
                                  styles.required,
                              )}
                            />
                          )}
                        {/* Price information */}
                        <FormTextField
                          name={"price_information"}
                          label={"Andra priser"}
                          description={
                            "Här kan du ange information om andra priser, till exempel VIP, matbiljett eller familjepris."
                          }
                          rows="4"
                          required={false}
                          className={clsx(
                            styles.field,
                            // false && styles.required,
                          )}
                        />
                      </div>
                    </div>
                  </fieldset>
                  {/* Age group */}
                  {/* {!formFields.age_group.hidden && (
                    <fieldset className=styles.field-wrapper event-form__field-group-fieldset>
                      <legend>
                        <span className=styles.field-label>
                          {" "}
                          {formFields.age_group.label}
                        </span>
                        {!formFields.age_group.hidden_description &&
                          formFields.age_group.description && (
                            <span className=styles.field-description>
                              {formFields.age_group.description}
                            </span>
                          )}
                      </legend>
                      <div className=styles.field-group>
                        <div>
                          {formFields.age_group.fields.map((child, index) => (
                            <FormInputField
                              css={css({
                                "--field-width": child.width,
                              })}
                              type="number"
                              key={index}
                              name={child.name}
                              id={child.name}
                              label={child.label}
                              description={
                                !child.hidden_description && child.description
                              }
                              placeholder={child.placeholder}
                              required={child.required}
                              className={clsx(
                                styles.field,
                                child.required && styles.required,
                              )}
                            />
                          ))}
                        </div>
                      </div>
                    </fieldset>
                  )} */}

                  {/* Organizer */}
                  {/* {!formFields.organizer.hidden && (
                    <Fragment>
                      <FormRadioGroupField
                        name={"useCustomOrganizer"}
                        label="Arrangör"
                        description="Skriv arrangörens namn och välj från de förslag som vi ger dig. Dyker inte arrangörens namn upp, kan du nämna det i din beskrivning."
                        // required={formFields["organizer-selector"].required}
                        className={clsx(
                          styles.field,
                          styles.radio,
                          // formFields["organizer-selector"].required &&
                          //   styles.required,
                        )}
                        options={[
                          { value: "", label: "Befintlig arrangör" },
                          { value: "yes", label: "Ny arrangör" },
                        ]}
                      />
                      {!values.useCustomOrganizer ? (
                        <FormReactSelectField
                          name="organizer.id"
                          label="Välj arrangör"
                          options={
                            eventOrganizers?.length
                              ? eventOrganizers
                                  .map((option) => ({
                                    value: option.id,
                                    label: option.name || option.title,
                                  }))
                                  .sort((a, b) =>
                                    collator.compare(a.label, b.label),
                                  )
                              : []
                          }
                          className={clsx(styles.field)}
                          fieldProps={{
                            isSearchable: true,
                          }}
                        />
                      ) : (
                        <div className=styles.field-group>
                          <div>
                            <FormInputField
                              type="text"
                              name="organizer.name"
                              label="Namn"
                              className={clsx(
                                styles.field,
                                // item.required && styles.required,
                              )}
                              // css={css({
                              //   "--field-width": "100%",
                              // })}
                            />
                            <FormInputField
                              type="text"
                              name="organizer.phone"
                              label="Telefonnummer"
                              className={clsx(styles.field)}
                            />
                            <FormInputField
                              type="email"
                              name="organizer.email"
                              label="E-postadress"
                              className={clsx(styles.field)}
                            />
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )} */}

                  {/* Location  */}
                  {!formFields.location.hidden && (
                    <Fragment>
                      {/* <FormRadioGroupField
                        name={"useCustomLocation"}
                        label="Plats"
                        description="Skriv platsens namn och välj från de förslag som vi ger dig. Dyker inte platsens namn upp, kan du nämna det i din beskrivning."
                        // required={formFields["organizer-selector"].required}
                        className={clsx(
                          styles.field,
                          styles.radio,
                          // formFields["organizer-selector"].required &&
                          //   styles.required,
                        )}
                        options={[
                          { value: "", label: "Befintlig plats" },
                          { value: "yes", label: "Ny plats" },
                        ]}
                      /> */}
                      {/* {!values.useCustomLocation ? (
                        <FormReactSelectField
                          name="location.id"
                          label="Välj plats"
                          options={
                            eventLocations?.length
                              ? eventLocations
                                  .map((option) => ({
                                    value: option.id,
                                    label: option.name || option.title,
                                  }))
                                  .sort((a, b) =>
                                    collator.compare(a.label, b.label),
                                  )
                              : []
                          }
                          className={clsx(styles.field)}
                          fieldProps={{
                            isSearchable: true,
                          }}
                        />
                      ) : ( */}
                      <fieldset className={clsx(styles.fieldGroupFieldset)}>
                        <legend>
                          <div
                            css={css`
                              font-size: var(--form-label-font-size);
                              font-weight: var(--form-label-weight);
                            `}
                          >
                            Plats
                          </div>
                          <p>
                            Skriv platsens namn och välj från de förslag som vi
                            ger dig. Dyker inte platsens namn upp, kan du nämna
                            det i din beskrivning.
                          </p>
                        </legend>
                        <div className={styles.fieldGroup}>
                          <div>
                            <FormInputField
                              type="text"
                              name="location.name"
                              label="Namn"
                              className={clsx(styles.field)}
                            />
                            <FormInputField
                              type="text"
                              name="location.street_address"
                              label="Gatuadress"
                              className={clsx(styles.field)}
                            />
                            <FormInputField
                              type="text"
                              name="location.postal_code"
                              label="Postnummer"
                              className={clsx(styles.field)}
                              css={css({
                                "--field-width": "40%",
                              })}
                            />
                            <FormInputField
                              type="text"
                              name="location.city"
                              label="Ort"
                              className={clsx(styles.field)}
                              css={css({
                                "--field-width": "60%",
                              })}
                            />
                          </div>
                        </div>
                      </fieldset>
                      {/* )} */}
                    </Fragment>
                  )}

                  {/* Accessibility */}
                  {!hideAccessibility && (
                    <Fragment>
                      <FormCheckboxField
                        name={formFields.accessibility.name}
                        id={formFields.accessibility.name}
                        label={formFields.accessibility.label}
                        description={
                          !formFields.accessibility.hidden_description &&
                          formFields.accessibility.description
                        }
                        required={formFields.accessibility.required}
                        className={clsx(
                          styles.field,
                          styles.radio,
                          formFields.accessibility.required && styles.required,
                        )}
                        options={formFields.accessibility.options}
                      />
                    </Fragment>
                  )}

                  {/* Categories */}
                  {!formFields.categories.hidden && (
                    <Fragment>
                      <FormReactSelectField
                        name="event_categories"
                        label="Kategorier"
                        description="Välj passande kategorier för ditt evenemang eller aktivitet."
                        options={
                          eventCategories?.length
                            ? eventCategories
                                .map((option) => ({
                                  value: option.databaseId,
                                  label: option.name,
                                }))
                                .sort((a, b) =>
                                  collator.compare(a.label, b.label),
                                )
                            : []
                        }
                        className={clsx(styles.field)}
                        multiple={true}
                        fieldProps={{
                          isSearchable: true,
                        }}
                      />
                    </Fragment>
                  )}

                  {/* KONTAKTINFORMATION */}
                  {((formFields.email && !formFields.email.hidden) ||
                    (formFields.phone && !formFields.phone.hidden)) && (
                    <fieldset className={clsx(styles.fieldGroupFieldset)}>
                      <legend>
                        <div
                          css={css`
                            font-size: var(--form-label-font-size);
                            font-weight: var(--form-label-weight);
                          `}
                        >
                          Kontaktinformation
                        </div>
                        <p>
                          Ange dina kontaktuppgifter nedan för eventuella
                          frågor.
                        </p>
                      </legend>
                      <div className={styles.fieldGroup}>
                        <div>
                          {/* Email */}
                          {formFields.email && !formFields.email.hidden && (
                            <FormInputField
                              type="email"
                              name={formFields.email.name}
                              id={formFields.email.name}
                              label={formFields.email.label}
                              description={
                                !formFields.email.hidden_description &&
                                formFields.email.description
                              }
                              inputProps={formFields.email.inputProps}
                              required={formFields.email.required}
                              className={clsx(
                                styles.field,
                                formFields.email.required && styles.required,
                              )}
                            />
                          )}
                          {/* Email Repeat */}
                          {formFields.email_repeat &&
                            !formFields.email_repeat.hidden && (
                              <FormInputField
                                type="email"
                                name={formFields.email_repeat.name}
                                id={formFields.email_repeat.name}
                                label={formFields.email_repeat.label}
                                description={
                                  !formFields.email_repeat.hidden_description &&
                                  formFields.email_repeat.description
                                }
                                inputProps={formFields.email_repeat.inputProps}
                                required={formFields.email_repeat.required}
                                className={clsx(
                                  styles.field,
                                  formFields.email_repeat.required &&
                                    styles.required,
                                )}
                              />
                            )}
                          {/* Phone */}
                          {formFields.phone && !formFields.phone.hidden && (
                            <FormInputField
                              type="text"
                              name={formFields.phone.name}
                              id={formFields.phone.name}
                              label={formFields.phone.label}
                              description={
                                !formFields.phone.hidden_description &&
                                formFields.phone.description
                              }
                              inputProps={formFields.phone.inputProps}
                              required={formFields.phone.required}
                              className={clsx(
                                styles.field,
                                formFields.phone.required && styles.required,
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </fieldset>
                  )}
                  {/*  GDPR Consent */}
                  {!!gdprConsentText && (
                    <div
                      className={clsx(styles.field, styles.required)}
                      css={css`
                        background-color: #f7f7f7;
                        padding: 1rem;
                      `}
                    >
                      <p
                        css={css`
                          margin-bottom: 0.5rem;
                        `}
                      >
                        {gdprConsentText}
                      </p>
                      <label>
                        <Field
                          type="checkbox"
                          name={"gdprConsent"}
                          value={"yes"}
                          required
                        />{" "}
                        Jag samtycker
                      </label>
                    </div>
                  )}
                  <div className={styles.fieldSubmit}>
                    {/* <p className=styles.field-description>
                    Klar? Klicka här för att skicka in ditt evenemang för
                    granskning.
                  </p> */}
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? "Skickar…" : "Skicka evenemang"}
                    </Button>
                    {!!submissionError && (
                      <div
                        css={css`
                          background: var(--color-danger);
                          color: var(--color-danger-text);
                          padding: 1rem;
                          margin-top: 1rem;
                        `}
                      >
                        {(submissionError instanceof ValidationError &&
                          submissionError.message) ||
                          `Ett fel uppstod. Prova igen.`}
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </ModuleWrapper>
  );
}
